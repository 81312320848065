import React, {useImperativeHandle, useRef} from 'react'

import {useTranslation} from 'next-i18next'
import {useMutationDeleteComment} from '@hook/query/timeline/useMutationDeleteComment'
import {useQueryGetComments} from '@hook/query/timeline/useQueryGetComments'
import {useQueryGetReplies} from '@hook/query/timeline/useQueryGetReplies'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'

const DeleteCommentPopupModal = (
    {type, id, parent_id}: {type: 'comment' | 'reply'; id: string; parent_id: string},
    ref: any,
) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    const {refetch: refetchComment} = useQueryGetComments(parent_id)
    const {refetch: refetchReply} = useQueryGetReplies(parent_id)
    const {mutateAsync} = useMutationDeleteComment()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {},
    }))

    const onClose = () => {
        modalRef.current.close()
    }

    const onDelete = async () => {
        createLog('event', 'timeline_detail_click_delete_comment')
        await mutateAsync(id, {
            onSuccess: async () => {
                type === 'comment' ? await refetchComment() : await refetchReply()
            },
        })
        onClose()
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.delete.comment.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[10px] mb-[20px]'}>
                {t('timeline.delete.comment.modal.desc')}
            </Text>
            <div className={'flex items-center gap-x-[5px]'}>
                <WizButton
                    text={t('timeline.modal.cancel.button.text')}
                    buttonType={'border'}
                    className={'w-full'}
                    onClick={onClose}
                />
                <WizButton
                    text={t('timeline.delete.modal.button.text')}
                    buttonType={'red'}
                    className={'w-full'}
                    onClick={onDelete}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(DeleteCommentPopupModal)
