import React from 'react'
import useFormatDate from '../../../../../src/hooks/useFormatDate'
import Text from '../../../../../src/components/Text/Text'
import Link from 'next/link'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    title?: string
    author?: string
    created_at?: number
    link: string
    target?: string
}

const TitleAndContentComponent: React.FC<IProps> = ({className, title, author, created_at, link, target}) => {
    const {formattedUTCOffset} = useFormatDate()
    return (
        <Link href={link} target={target} onClick={() => createLog('event', 'timeline_link_click', {link})}>
            <div
                className={`px-[20px] py-[10px] hover:bg-gray09 dark:hover:bg-dark_gray09 rounded-[5px] transition-colors duration-100 ${className}`}>
                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 line-clamp-1'}>{title}</Text>
                <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                    {author && author + ' | '}
                    {formattedUTCOffset(created_at)}
                </Text>
            </div>
        </Link>
    )
}
export default TitleAndContentComponent
