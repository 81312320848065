import React, {useImperativeHandle, useRef} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {URL_ACCOUNT_API} from '@constant/url'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'

interface IProps {}

const ExchangeSupportPopupModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {},
    }))

    const onClose = () => {
        modalRef.current.close()
    }

    const onConnectAnExchange = () => {
        void router?.push(URL_ACCOUNT_API)
        onClose()
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.daily.report.share.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[10px] mb-[20px]'}>
                {t('timeline.daily.report.share.modal.desc')}
            </Text>
            <WizButton
                text={t('timeline.daily.report.share.modal.connect.exchange.button.text')}
                buttonType={'primary'}
                className={'w-full'}
                onClick={onConnectAnExchange}
            />
        </WizContentModal>
    )
}

export default React.forwardRef(ExchangeSupportPopupModal)
