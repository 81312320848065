import React from 'react'
import {useQueryGetComments} from '@hook/query/timeline/useQueryGetComments'
import CommentListItem from '../components/comment/items/CommentListItem'

interface IProps {
    className?: string
    postId?: string
}

const TimelineCommentListContainer: React.FC<IProps> = ({className, postId}) => {
    const {data} = useQueryGetComments(postId, true)
    return (
        <div className={`flex flex-col gap-y-[20px] p-[20px] ${className}`}>
            {data?.map((comment, index) => {
                return <CommentListItem key={comment?.id} {...comment} />
            })}
        </div>
    )
}
export default TimelineCommentListContainer
