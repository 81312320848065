import React from 'react'
import {useTranslation} from 'next-i18next'
import TimelineNavBackButton from '../components/TimelineNavBackButton'
import TimelineDetailContentsComponent from '../components/detail/TimelineDetailContentsComponent'
import TimelineCommentContainer from './TimelineCommentContainer'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailContainer: React.FC<IProps> = ({className, postId}) => {
    const {t} = useTranslation()
    return (
        <article className={`${className}`}>
            <TimelineNavBackButton title={t('timeline.detail.title')} />
            <TimelineDetailContentsComponent postId={postId} className={'mt-[20px] sm:mt-[15px]'} />
            <TimelineCommentContainer postId={postId} className={'mt-[10px]'} />
        </article>
    )
}
export default TimelineDetailContainer
