import {useMemo} from 'react'
import {Feed} from '@api/timeline/type'
import {useLoginStore} from '@store/LoginStore'
import {useInfiniteQueryGetFeeds} from '@hook/query/timeline/useInfiniteQueryGetFeeds'
import {useInfiniteQueryGetUserTimeline} from '@hook/query/timeline/useInfiniteQueryGetUserTimeline'
import {useQueryGetBanUserIdList} from '@hook/query/ban/useQueryGetBanUserIdList'
import {useTimelineStore} from '../store/useTimelineStore'

export const useTimelineListData = (enabled?: boolean) => {
    const id = useLoginStore(state => state.id)
    const {activeTab} = useTimelineStore(state => state)

    const {data: banList} = useQueryGetBanUserIdList()

    const feeds = useInfiniteQueryGetFeeds(
        activeTab === 'all' ? Feed.All : Feed.Subscribed,
        enabled && activeTab !== 'my',
    )

    const userTimeline = useInfiniteQueryGetUserTimeline(id, enabled && activeTab === 'my')

    const timeline = useMemo(() => {
        if (activeTab === 'my') return userTimeline
        const timeline = feeds?.feeds?.filter(e => !banList?.includes(e.user_id || ''))
        return {...feeds, feeds: timeline}
    }, [activeTab, feeds?.feeds, userTimeline, banList])

    return timeline
}
