import React from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import IconBack from '@svg/common/ic_btn_back.svg'

type Props = {
    title?: string
}

const TimelineNavBackButton = ({title}: Props) => {
    const {t} = useTranslation()
    const router = useRouter()
    const routeToBack = () => router.back()
    return (
        <div className={'inline-block mb-[15px] sm:block sm:px-[10px] cursor-pointer'} onClick={routeToBack}>
            <div className={'flex items-center gap-x-[10px]'}>
                <span className={'w-[24px] p-[4px]'}>
                    <IconBack className={'fill-gray01 dark:fill-dark_gray01'} />
                </span>
                <div className={'sm:flex-1'}>
                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 sm:hidden'}>
                        {t('timeline.post.back.title')}
                    </Text>
                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 text-center hidden sm:block'}>
                        {title}
                    </Text>
                </div>
                <span className={'w-[24px] h-[24px]'} />
            </div>
        </div>
    )
}
export default React.memo(TimelineNavBackButton)
