import React from 'react'
import {useTranslation} from 'next-i18next'

import {COMMENT_INPUT_ID} from '../../../features/constant/constant'
import {useQueryGetPost} from '@hook/query/timeline/useQueryGetPost'
import {useMutationLikePost} from '@hook/query/timeline/useMutationLikePost'
import {showToast} from '@component/snackbar/WizToastMessage'
import useLogin from '@hook/useLogin'
import {useLoginModalStore} from '@store/LoginModalStore'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'

import ViewCountIcon from '@svg/timeline/ic_view_count.svg'
import LikeIcon from '@svg/timeline/ic_heart.svg'
import CommentIcon from '@svg/timeline/ic_comment.svg'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailMainOperations: React.FC<IProps> = ({className, postId}) => {
    const {t} = useTranslation()
    const {isLogin} = useLogin()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const {data, refetch} = useQueryGetPost(postId)
    const {mutateAsync, isLoading} = useMutationLikePost()
    const onClickLike = async () => {
        if (!isLogin()) {
            showLoginModal()
            return
        }
        if (!data) return

        createLog('event', 'timeline_detail_click_like')
        await mutateAsync(
            {is_liked: data?.is_liked, parent_id: postId},
            {
                onSuccess: async ({data: {point}}) => {
                    await refetch()
                    if (!data?.is_liked && point) {
                        showToast(t('timeline.like.point.alert.title', {point}))
                    }
                },
            },
        )
    }

    const onClickComment = () => {
        const element = document.getElementById(COMMENT_INPUT_ID)
        element?.focus()
    }
    return (
        <div className={'flex items-center gap-x-[10px] sm:gap-x-0'}>
            <div className={'flex items-center gap-x-[5px] p-[10px]'}>
                <ViewCountIcon className={'fill-gray02 dark:fill-dark_gray02'} />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.view.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{data?.views}</Text>
            </div>
            <div className={'flex items-center gap-x-[5px] p-[10px] cursor-pointer'} onClick={onClickLike}>
                <LikeIcon
                    className={
                        data?.is_liked
                            ? 'fill-red dark:fill-dark_red stroke-red dark:stroke-dark_red'
                            : 'stroke-gray02 dark:stroke-dark_gray02'
                    }
                />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.like.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{data?.likes_count}</Text>
            </div>
            <div className={'flex items-center gap-x-[5px] p-[10px] cursor-pointer'} onClick={onClickComment}>
                <CommentIcon className={'fill-gray02 dark:fill-dark_gray02'} />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.comment.title')}
                </Text>
            </div>
        </div>
    )
}
export default TimelineDetailMainOperations
