import React from 'react'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

import {COMMENT_INPUT_ID} from '../../../features/constant/constant'

import {TCreateCommentParams} from '@api/timeline/type'
import {useQueryGetReplies} from '@hook/query/timeline/useQueryGetReplies'
import {useMutationCreateComment} from '@hook/query/timeline/useMutationCreateComment'
import {useCommentStore} from '../store/useCommentStore'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import TimelineCommentInput from './TimelineCommentInput'

interface IProps {
    className?: string
    id?: string
    parent_id?: string
}

const MAX_COMMENT_LENGTH = 2000
const TimelineCommentEditor: React.FC<IProps> = ({className, id, parent_id}) => {
    const {t} = useTranslation()
    const {commentInfo, setCommentInfo} = useCommentStore(state => state)
    const [content, setContent] = React.useState('')

    useIsomorphicLayoutEffect(() => {
        setContent(`@${commentInfo?.name} `)
    }, [commentInfo?.name])

    const {refetch} = useQueryGetReplies(parent_id ? parent_id : id)
    const {mutateAsync} = useMutationCreateComment()

    const clearData = () => {
        setContent('')
        setCommentInfo(undefined)
    }

    const onClickCreateComment = async () => {
        createLog('event', 'timeline_detail_create_comment')
        const comment: TCreateCommentParams = {
            parent_id: parent_id ? parent_id : id,
            mention_user_id: commentInfo?.id,
            content,
        }

        await mutateAsync(comment, {
            onSuccess: async () => {
                await refetch()
                clearData()
            },
        })
    }
    return (
        <div className={'w-full flex flex-col gap-y-[5px]'}>
            <TimelineCommentInput
                autoFocus
                id={COMMENT_INPUT_ID}
                value={content}
                placeholder={t('timeline.detail.reply.editor.placeholder', {name: commentInfo?.name || ''})}
                maxLength={MAX_COMMENT_LENGTH}
                onChange={e => setContent(e?.currentTarget?.value)}
            />
            <div className={'flex items-center gap-x-[10px] justify-end'}>
                <div className={'flex items-center'}>
                    <Text
                        className={classNames(
                            'text-ti2',
                            content?.length >= MAX_COMMENT_LENGTH
                                ? 'text-red_shade dark:text-dark_red_shade'
                                : 'text-gray01 dark:text-dark_gray01',
                        )}>
                        {content.length || 0}
                    </Text>
                    <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>/2,000</Text>
                </div>
                <div className={'flex items-center gap-x-[4px]'}>
                    <WizButton
                        text={t('timeline.edit.cancel.button.text')}
                        size={'small'}
                        buttonType={'border'}
                        onClick={clearData}
                    />
                    <WizButton
                        disabled={content.length <= 0}
                        text={t('timeline.detail.comment.editor.send.button.text')}
                        size={'small'}
                        buttonType={'primary'}
                        onClick={onClickCreateComment}
                    />
                </div>
            </div>
        </div>
    )
}
export default TimelineCommentEditor
