import React from 'react'
import dynamic from 'next/dynamic'

import {TPost} from '@api/timeline/type'
import {TTimelineOperatorMethod} from '@pages/timeline/features/components/timeline/list/TimelineListComponent'

import TimelineMainOperations from './TimelineMainOperations'

const TimelineOtherOperations = dynamic(import('./TimelineOtherOperations'), {
    ssr: false,
})

interface IProps extends Pick<TPost, 'id' | 'user' | 'views' | 'is_liked' | 'likes_count'> {
    className?: string
    onClickOperations?: TTimelineOperatorMethod
}

const TimelineItemOperations: React.FC<IProps> = ({
    className,
    id,
    user,
    views,
    is_liked,
    likes_count,
    onClickOperations,
}) => {
    return (
        <div className={`flex items-center justify-between ${className}`}>
            <TimelineMainOperations
                id={id}
                user_id={user?.id}
                views={views}
                is_liked={is_liked}
                likes_count={likes_count}
            />
            <TimelineOtherOperations id={id} user={user} onClickOperations={onClickOperations} />
        </div>
    )
}
export default TimelineItemOperations
