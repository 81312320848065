import React, {useEffect} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'next-i18next'

import {apiPostTranslate, TTranslationRequest} from '@api/translate/translate'

import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import Spinner from '@component/Spinner/Spinner'

import LanguageSelectorIcon from '@svg/gnb/ic_language_selector.svg'

interface IProps {
    className?: string
    postId?: string
    content?: string
    language?: string
    isTranslated: boolean
    setIsTranslated: React.Dispatch<React.SetStateAction<boolean>>
    setTranslatedText: (text: string) => void
}

const TimelineDetailContentsTranslate = ({
    className,
    postId,
    content,
    language,
    isTranslated,
    setIsTranslated,
    setTranslatedText,
}: IProps) => {
    const {t} = useTranslation()
    const [lang, setLang] = React.useState<string>()

    const {mutateAsync, isLoading} = useMutation({
        mutationFn: async (params: TTranslationRequest) => await apiPostTranslate(params),
    })

    useEffect(() => {
        if (!navigator) return
        setLang(navigator.language?.split('-')?.at(0))
    }, [])

    const translateText = async () => {
        if (!content) return
        if (language === lang) return
        if (isTranslated) {
            setIsTranslated(prev => !prev)
            return
        }

        createLog('event', 'timeline_detail_click_translate')
        const {data: res, error} = await mutateAsync({
            id: postId,
            type: 'post',
            language: lang,
        })
        if (!error) {
            setIsTranslated(prev => !prev)
            setTranslatedText(res?.content.trim())
        }
    }

    if (language === lang) return null
    if (!content) return null
    return (
        <div className={'flex items-center gap-x-[5px] cursor-pointer'} onClick={translateText}>
            <LanguageSelectorIcon className={`w-[16px] fill-gray02 dark:fill-dark_gray02`} />
            {isLoading ? (
                <Spinner visible={true} size={'smallest'} />
            ) : (
                <Text
                    className={
                        'text-body2 text-gray02 dark:text-dark_gray02 underline decoration-gray03 dark:decoration-dark_gray03'
                    }>
                    {isTranslated ? t('timeline.detail.original.text') : t('timeline.detail.translate.text')}
                </Text>
            )}
        </div>
    )
}
export default TimelineDetailContentsTranslate
