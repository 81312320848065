import React from 'react'
import {useTranslation} from 'next-i18next'

import {TimelineTab} from '../TimelineTabNavigation'
import Text from '@component/Text/Text'

import NoticeIcon from '@svg/common/ic_notice.svg'

interface IProps {
    className?: string
    activeTab?: TimelineTab
}

const TimelineListEmptyItem: React.FC<IProps> = ({className, activeTab}) => {
    const {t} = useTranslation()
    if (activeTab === 'all') return null
    return (
        <div className={`flex flex-col items-center justify-center py-[30px] ${className}`}>
            <NoticeIcon className={'w-[36px] fill-gray02 dark:fill-dark_gray02 mb-[20px]'} />
            <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>{t('timeline.list.empty.title')}</Text>
        </div>
    )
}
export default TimelineListEmptyItem
