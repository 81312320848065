import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'
import {useMutationReportPost} from '@hook/query/timeline/useMutationReportPost'
import WizContentModal from '@component/modal/WizContentModal'
import WizButton from '@component/buttons/WizButton'
import WizTextArea from '@component/textArea/WizTextArea'
import {showToast} from '@component/snackbar/WizToastMessage'

interface IProps {}

const ReportPostPopupModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    const [reason, setReason] = React.useState('')
    const [parent_id, setParent_id] = React.useState<string>()

    const {mutateAsync} = useMutationReportPost()

    useImperativeHandle(ref, () => ({
        show: ({parent_id}: {parent_id: string}) => {
            modalRef.current.show()
            setParent_id(parent_id)
        },
        close: () => {},
    }))

    const onClose = () => {
        modalRef.current.close()
        setParent_id(undefined)
    }

    const onReport = async () => {
        if (!parent_id) return
        createLog('event', 'timeline_click_report')
        await mutateAsync({parent_id, reason})
        showToast(t('timeline.success.report.alert.title'))
        onClose()
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.report.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <WizTextArea
                className={'text-gray01 dark:text-dark_gray01 min-h-[150px] mt-[10px] mb-[20px]'}
                placeholder={t('timeline.report.modal.input.placeholder')}
                text={reason}
                onChanged={setReason}
            />
            <div className={'flex items-center gap-x-[5px]'}>
                <WizButton
                    text={t('timeline.modal.cancel.button.text')}
                    buttonType={'border'}
                    className={'w-full'}
                    onClick={onClose}
                />
                <WizButton
                    text={t('timeline.report.modal.button.text')}
                    buttonType={'primary'}
                    className={'w-full'}
                    onClick={onReport}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(ReportPostPopupModal)
