import React from 'react'
import {useQueryGetReplies} from '@hook/query/timeline/useQueryGetReplies'
import ReplyListItem from './items/ReplyListItem'

interface IProps {
    className?: string
    parentId?: string
}

const TimelineReplyListComponent: React.FC<IProps> = ({className, parentId}) => {
    const {data} = useQueryGetReplies(parentId, true)
    return (
        <div className={`pl-[40px] flex flex-col gap-y-[20px] ${className}`}>
            {data?.map(reply => {
                return <ReplyListItem key={reply.id} {...reply} />
            })}
        </div>
    )
}
export default TimelineReplyListComponent
