import React from 'react'
import {useTranslation} from 'next-i18next'

import {useTimelineStore} from '../../../../store/useTimelineStore'
import {WSOption} from '@component/select/WizSelectBox'
import {PostVisibility, PostVisibilityType} from '@api/timeline/type'

import WizTextSelectBox from '@component/select/WizTextSelectBox'

import PublicIcon from '@svg/timeline/ic_btn_public.svg'
import PrivateIcon from '@svg/timeline/ic_btn_private.svg'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const TimelineEditorPrivacyOperation: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {visibility, setPrivacy} = useTimelineStore(state => state)

    const visibilityOptions: WSOption[] = [
        {
            id: PostVisibility.Public,
            text: t('timeline.editor.privacy.public.button.text'),
            value: PostVisibility.Public,
        },
        {
            id: PostVisibility.Private,
            text: t('timeline.editor.privacy.private.button.text'),
            value: PostVisibility.Private,
        },
    ]

    const onClickPrivacy = (visibility: PostVisibilityType) => {
        createLog('event', 'timeline_click_editor_visibility', {visibility})
        setPrivacy(visibility)
    }
    return (
        <WizTextSelectBox
            selectedOption={visibility}
            options={visibilityOptions}
            leftIcon={
                visibility === PostVisibility.Public ? (
                    <PublicIcon className={'fill-gray01 dark:fill-dark_gray01'} />
                ) : (
                    <PrivateIcon className={'fill-gray01 dark:fill-dark_gray01'} />
                )
            }
            onChange={option => onClickPrivacy(option?.value)}
        />
    )
}
export default React.memo(TimelineEditorPrivacyOperation)
