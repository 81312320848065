import React from 'react'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useQueryGetPost} from '@hook/query/timeline/useQueryGetPost'
import UserThumbnail from '@component/images/UserThumbnail'
import TimelineDetailCommentEditor from './TimelineDetailCommentEditor'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailEditorComponent: React.FC<IProps> = ({className, postId}) => {
    const {data: me} = useQueryFetchMe(undefined, false)
    const {data} = useQueryGetPost(postId)
    return (
        <div
            className={`flex gap-x-[10px] px-[20px] py-[15px] sm:py-[10px] border-b border-gray08 dark:border-dark_gray08 ${className}`}>
            <UserThumbnail src={me?.thumbnail} size={30} />
            <TimelineDetailCommentEditor id={postId} userId={data?.user_id} />
        </div>
    )
}
export default TimelineDetailEditorComponent
