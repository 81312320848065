import React, {useImperativeHandle, useRef} from 'react'

import {useTranslation} from 'next-i18next'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'

const TimelineMobileEditorErrorModal = ({}, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    const [error, setError] = React.useState('')

    useImperativeHandle(ref, () => ({
        show: ({error}: {error: string}) => {
            modalRef.current.show()
            setError(error)
        },
        close: () => {
            onClose()
        },
    }))

    const onClose = () => {
        modalRef.current.close()
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.editor.error.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[10px] mb-[20px]'}>{error}</Text>
            <WizButton
                text={t('timeline.editor.error.modal.confirm.text')}
                buttonType={'border'}
                className={'w-full'}
                onClick={onClose}
            />
        </WizContentModal>
    )
}

export default React.forwardRef(TimelineMobileEditorErrorModal)
