import React from 'react'
import classNames from 'classnames'

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TimelineCommentInput: React.FC<IProps> = ({className, ...props}) => {
    return (
        <textarea
            className={classNames(
                'w-full p-[10px] min-h-[42px]',
                'text-input text-gray01 dark:text-dark_gray01 resize-none',
                'bg-gray08 dark:bg-dark_gray08 border border-gray06 dark:border-dark_gray06 rounded-[5px]',
                'disabled:hover:border-gray06 dark:disabled:hover:border-dark_gray06 hover:border-gray01 dark:hover:border-dark_gray01 ',
                'focus:border-gray01 dark:focus:border-dark_gray01',
                'placeholder:text-gray03 dark:placeholder:text-dark_gray03 placeholder:disabled:text-gray04 dark:placeholder:disabled:text-dark_gray04',
            )}
            {...props}
        />
    )
}
export default TimelineCommentInput
