import React from 'react'
import {useTranslation} from 'next-i18next'
import {useTimelineChannel} from '../../../../store/useTimelineChannel'

import {WSOption} from '@component/select/WizSelectBox'
import {TimelineChannel} from '@api/timeline/type'
import {createLog} from '@util/logs'

import WizTextSelectBox from '@component/select/WizTextSelectBox'
import ChannelIcon from '@svg/timeline/ic_btn_channel.svg'

interface IProps {
    className?: string
}

const TimelineEditorChannelOperation: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {channel, setChannel} = useTimelineChannel()

    const channelOptions: WSOption[] = [
        {id: 'en', text: t('timeline.editor.channel.en.button.text'), value: 'en'},
        {id: 'ko', text: t('timeline.editor.channel.ko.button.text'), value: 'ko'},
    ]

    const onClickChannel = (channel: TimelineChannel) => {
        createLog('event', 'timeline_click_editor_channel', {channel})
        setChannel(channel)
    }
    return (
        <WizTextSelectBox
            selectedOption={channel === 'all' ? 'en' : channel}
            options={channelOptions}
            leftIcon={<ChannelIcon className={'fill-gray01 dark:fill-dark_gray01'} />}
            onChange={option => onClickChannel(option?.value)}
        />
    )
}
export default React.memo(TimelineEditorChannelOperation)
