import React, {useMemo} from 'react'
import {router} from 'next/client'
import {useTranslation} from 'next-i18next'

import {URL_PORTFOLIO, URL_TIMELINE_USER} from '@constant/url'
import {TPost} from '@api/timeline/type'
import {WMOption} from '@component/menu/WizMenu/WizMenu'
import useLogin from '@hook/useLogin'
import useFormatDate from '@hook/useFormatDate'
import {useMutationFollowUnfollow} from '@hook/query/timeline/useMutationFollowUnfollow'
import useQueryFollowing from '@hook/query/user/account/subscriber/useQueryFollowing'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizCustomMenu from '@component/menu/WizMenu/WizCustomMenu'
import UserThumbnail from '@component/images/UserThumbnail'
import ProTag from '@component/tags/ProTag'

import ClockIcon from '@svg/timeline/ic_clock.svg'

interface IProps extends Pick<TPost, 'user' | 'created_at'> {
    className?: string
    isFollowing?: boolean
}

const TimelineItemHeader: React.FC<IProps> = ({className, user, created_at, isFollowing}) => {
    const {t} = useTranslation()
    const {getRelativeTime} = useFormatDate()
    const {id, isMe, isLogin} = useLogin(user?.id)

    const {refresh} = useQueryFollowing(id, false)
    const {mutateAsync} = useMutationFollowUnfollow()

    const menuItems: WMOption[] = useMemo(() => {
        const list: WMOption[] = []

        if (!user.is_official) {
            list.push({
                id: 'portfolio',
                value: (
                    <Text
                        className={
                            'px-[12px] py-[15px] text-body1 text-gray02 dark:text-dark_gray02 hover:bg-gray07 dark:hover:bg-dark_gray07'
                        }>
                        {t('timeline.item.header.portfolio.button.text')}
                    </Text>
                ),
                onClick: () => {
                    createLog('event', 'timeline_click_portfolio')
                    router.push(URL_PORTFOLIO(user?.id))
                },
            })
        }
        list.push({
            id: 'posts',
            value: (
                <Text
                    className={
                        'px-[12px] py-[15px] text-body1 text-gray02 dark:text-dark_gray02 hover:bg-gray07 dark:hover:bg-dark_gray07'
                    }>
                    {t('timeline.item.header.posts.button.text')}
                </Text>
            ),
            onClick: () => {
                createLog('event', 'timeline_click_user_timeline')
                router.push(URL_TIMELINE_USER(user?.id))
            },
        })

        return list
    }, [t, user?.id, user?.is_official, router])

    const onClickFollowUnfollow = async () => {
        createLog('event', 'timeline_click_follow')
        await mutateAsync(
            {isFollowing, user_id: user?.id},
            {
                onSuccess: async ({data}) => {
                    if (!data?.success) return
                    await refresh()
                },
            },
        )
    }
    return (
        <div className={`flex items-center justify-between gap-x-[10px] ${className}`}>
            <WizCustomMenu items={menuItems} placement={'bottom-start'}>
                <div className={'flex justify-between gap-x-[10px] cursor-pointer'}>
                    <UserThumbnail src={user?.thumbnail} size={40} containerClassName={'sm:hidden'} />
                    <UserThumbnail src={user?.thumbnail} size={30} containerClassName={'hidden sm:block'} />
                    <div className={'flex-1'}>
                        <div className={'flex items-center gap-x-[3px]'}>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 mb-[3px] line-clamp-1'}>
                                {user?.name}
                            </Text>
                            {user?.is_pro && <ProTag size={'small'} />}
                        </div>
                        <div className={'flex items-center gap-x-[5px]'}>
                            <ClockIcon className={'fill-gray03 dark:fill-dark_gray03'} />
                            <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>
                                {getRelativeTime(created_at)}
                            </Text>
                        </div>
                    </div>
                </div>
            </WizCustomMenu>

            {isLogin() && !isMe && !user?.is_official && isFollowing !== undefined ? (
                <WizButton
                    text={
                        isFollowing
                            ? t('timeline.item.header.unfollow.button.text')
                            : '+ ' + t('timeline.item.header.follow.button.text')
                    }
                    buttonType={isFollowing ? 'border' : 'primary'}
                    size={'smallest'}
                    onClick={onClickFollowUnfollow}
                />
            ) : null}
        </div>
    )
}
export default React.memo(TimelineItemHeader)
