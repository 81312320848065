import {useLocalStorage} from 'usehooks-ts'
import {TimelineChannel} from '@api/timeline/type'

const TimelineChannelKey = 'timeline-channel'

export const useTimelineChannel = () => {
    const [channel, setChannel] = useLocalStorage<TimelineChannel>(TimelineChannelKey, undefined)

    return {channel, setChannel}
}
