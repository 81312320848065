import React, {useCallback, useRef} from 'react'
import {useTranslation} from 'next-i18next'

import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginStore} from '@store/LoginStore'
import {useLoginModalStore} from '@store/LoginModalStore'

import Text from '@component/Text/Text'
import FirstJoinDailyReportModal from '@feature/common/components/DailyReportModal/FirstJoinDailyReportModal'
import TimelineMobileEditorErrorModal from '../modals/TimelineMobileEditorErrorModal'
import ExchangeSupportPopupModal from '../../features/modals/ExchangeSupportPopupModal'
import {useRouter} from 'next/router'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    images?: string[]
    setImages: (images?: string[]) => void
}

const TimelineMobileDailyReportButton = ({className, images, setImages}: IProps) => {
    const {t} = useTranslation()
    const rotuer = useRouter()

    const dailyReportModalRef = React.useRef<any>(null)
    const exchangeSupportPopupModalRef = React.useRef<any>(null)
    const errorModalRef = useRef(null)

    const {data: me} = useQueryFetchMe(undefined, false)

    const {id} = useLoginStore()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    useIsomorphicLayoutEffect(() => {
        if (rotuer.query.report) {
            void onClickShareDailyReport()
            rotuer.replace({query: {}})
        }
    }, [rotuer.query])

    const checkLogin = () => {
        if (id && id.length > 0) return true
        showLoginModal()
    }

    const onClickShareDailyReport = () => {
        if (!checkLogin()) return

        if (me?.keys && me?.keys.length > 0) {
            dailyReportModalRef.current?.show()
            return
        }

        exchangeSupportPopupModalRef.current?.show()
    }

    const onSaveCallBack = useCallback(
        (image_url: string) => {
            if (images && images.length >= 5) {
                errorModalRef.current?.show({error: t('timeline.editor.post.image.limit.error')})
                return
            }
            setImages([...(images || []), image_url])
        },
        [images],
    )
    return (
        <div
            className={`flex items-center gap-x-[5px] px-[10px] py-[7px] bg-gray09 dark:bg-dark_gray09 rounded-[3px] cursor-pointer ${className}`}
            onClick={onClickShareDailyReport}>
            <Text className={'text-body2 text-gray01 dark:text-dark_gray01'}>
                + {t('timeline.editor.mobile.daily.report.button.text')}
            </Text>
            <FirstJoinDailyReportModal
                ref={dailyReportModalRef}
                type={'loader'}
                className={'!-z-bottomSheet'}
                onSaveCallBack={onSaveCallBack}
            />

            <ExchangeSupportPopupModal ref={exchangeSupportPopupModalRef} />
            <TimelineMobileEditorErrorModal ref={errorModalRef} />
        </div>
    )
}
export default TimelineMobileDailyReportButton
