import React from 'react'
import TimelineEditorImageOperation from './items/TimelineEditorImageOperation'
import TimelineEditorChannelOperation from './items/TimelineEditorChannelOperation'
import TimelineEditorPrivacyOperation from './items/TimelineEditorPrivacyOperation'

interface IProps {
    className?: string
    images?: string[]
    setImages: (images?: string[]) => void
    setHasImageError: (hasError: boolean) => void
}

const TimelineEditorMainOperations: React.FC<IProps> = ({className, images, setImages, setHasImageError}) => {
    return (
        <div className={`flex items-center gap-x-[5px] px-[5px] ${className}`}>
            <TimelineEditorImageOperation
                id={'post-editor'}
                images={images}
                setImages={setImages}
                setHasImageError={setHasImageError}
            />
            <TimelineEditorChannelOperation />
            <TimelineEditorPrivacyOperation />
        </div>
    )
}
export default TimelineEditorMainOperations
