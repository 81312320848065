import {create} from 'zustand'
import {TimelineTab} from '../components/timeline/list/TimelineTabNavigation'
import {PostVisibility, PostVisibilityType, TimelineChannel} from '@api/timeline/type'

type TimelineState = {
    activeTab: TimelineTab
    setActiveTab: (activeTab: TimelineTab) => void

    // editor states
    visibility: PostVisibilityType
    setPrivacy: (privacy?: PostVisibilityType) => void
}
export const useTimelineStore = create<TimelineState>(set => ({
    activeTab: 'all',
    setActiveTab: activeTab => set({activeTab}),
    // editor states
    visibility: PostVisibility.Public,
    setPrivacy: visibility => set({visibility}),
}))
