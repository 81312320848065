import React from 'react'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'

import {COMMENT_INPUT_ID} from '../../../features/constant/constant'

import {TCreateCommentParams} from '@api/timeline/type'
import {useLoginModalStore} from '@store/LoginModalStore'
import useLogin from '@hook/useLogin'
import {useMutationCreateComment} from '@hook/query/timeline/useMutationCreateComment'
import {useQueryGetComments} from '@hook/query/timeline/useQueryGetComments'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import TimelineCommentInput from '../comment/TimelineCommentInput'

interface IProps {
    className?: string
    id?: string
    userId?: string
}

const MAX_COMMENT_LENGTH = 2000
const TimelineDetailCommentEditor: React.FC<IProps> = ({className, id, userId}) => {
    const {t} = useTranslation()
    const {isLogin} = useLogin()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const [content, setContent] = React.useState('')

    const {refetch} = useQueryGetComments(id)
    const {mutateAsync} = useMutationCreateComment()

    const onClickEditor = (e?: React.MouseEvent<HTMLTextAreaElement>) => {
        if (isLogin()) return
        e?.preventDefault()
        e?.stopPropagation()
        showLoginModal()
    }

    const onClickCreateComment = async () => {
        if (!isLogin()) {
            showLoginModal()
            return
        }

        const comment: TCreateCommentParams = {
            parent_id: id,
            mention_user_id: userId,
            content,
        }

        await mutateAsync(comment, {
            onSuccess: async () => {
                await refetch()
                setContent('')
            },
        })
    }
    return (
        <div className={'w-full flex flex-col gap-y-[5px]'}>
            <TimelineCommentInput
                id={COMMENT_INPUT_ID}
                value={content}
                placeholder={t('timeline.detail.comment.editor.placeholder')}
                maxLength={MAX_COMMENT_LENGTH}
                onChange={e => setContent(e?.currentTarget?.value)}
                onClick={onClickEditor}
            />
            <div className={'flex items-center gap-x-[10px] justify-end'}>
                <div className={'flex items-center'}>
                    <Text
                        className={classNames(
                            'text-ti2',
                            content?.length >= MAX_COMMENT_LENGTH
                                ? 'text-red_shade dark:text-dark_red_shade'
                                : 'text-gray01 dark:text-dark_gray01',
                        )}>
                        {content.length || 0}
                    </Text>
                    <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>/2,000</Text>
                </div>
                <WizButton
                    disabled={content.length <= 0}
                    text={t('timeline.detail.comment.editor.send.button.text')}
                    size={'small'}
                    buttonType={'primary'}
                    onClick={onClickCreateComment}
                />
            </div>
        </div>
    )
}
export default TimelineDetailCommentEditor
