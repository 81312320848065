import React, {useMemo, useRef} from 'react'
import Link from 'next/link'

import {WMOption} from '@component/menu/WizMenu/WizMenu'
import {TComment} from '@api/timeline/type'
import {URL_TIMELINE_USER} from '@constant/url'
import {useTranslation} from 'next-i18next'
import useFormatDate from '@hook/useFormatDate'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginStore} from '@store/LoginStore'

import Text from '@component/Text/Text'
import ProTag from '@component/tags/ProTag'
import WizIconButton from '@component/buttons/WizIconButton'
import WizCustomMenu from '@component/menu/WizMenu/WizCustomMenu'

import DeleteCommentPopupModal from '../../../../features/modals/DeleteCommentPopupModal'
import ReportPostPopupModal from '../../../../features/modals/ReportPostPopupModal'

import MoreIcon from '@svg/common/ic_btn_more.svg'

interface IProps extends Pick<TComment, 'id' | 'parent_id' | 'user' | 'created_at'> {
    className?: string
}

const ReplyItemHeader: React.FC<IProps> = ({className, id, parent_id, user, created_at}) => {
    const {t} = useTranslation()
    const {getRelativeTime} = useFormatDate()

    const {id: loginId} = useLoginStore()
    const {data: me} = useQueryFetchMe(undefined, false)

    const deleteCommentRef = useRef(null)
    const reportCommentRef = useRef<any>(null)

    const myMenuItems: WMOption[] = useMemo(() => {
        const list: WMOption[] = [
            {
                id: 'delete',
                value: (
                    <Text
                        className={
                            'px-[12px] py-[15px] text-body1 text-gray02 dark:text-dark_gray02 hover:bg-gray07 dark:hover:bg-dark_gray07'
                        }>
                        {t('timeline.item.menu.delete.title')}
                    </Text>
                ),
                onClick: () => deleteCommentRef.current?.show(),
            },
        ]

        return list
    }, [t])

    const userMenuItems: WMOption[] = useMemo(() => {
        const list: WMOption[] = [
            {
                id: 'report',
                value: (
                    <Text
                        className={
                            'px-[12px] py-[15px] text-body1 text-gray02 dark:text-dark_gray02 hover:bg-gray07 dark:hover:bg-dark_gray07'
                        }>
                        {t('timeline.item.menu.report.title')}
                    </Text>
                ),
                onClick: () => reportCommentRef.current?.show({parent_id: id}),
            },
        ]
        return list
    }, [t, id])

    return (
        <div className={`flex items-center justify-between gap-x-[10px] ${className}`}>
            <div className={'w-full flex items-center gap-x-[10px]'}>
                <Link href={URL_TIMELINE_USER(user?.id)}>
                    <div className={'flex items-center gap-x-[3px]'}>
                        <Text
                            className={
                                'text-ti2 text-gray01 dark:text-dark_gray01 mb-[3px] cursor-pointer hover:underline'
                            }>
                            {user?.name}
                        </Text>
                        {user?.is_pro && <ProTag size={'small'} />}
                    </div>
                </Link>
                <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>{getRelativeTime(created_at)}</Text>
            </div>

            <WizCustomMenu
                items={user?.id === me?.id ? myMenuItems : userMenuItems}
                placement={'bottom-start'}
                className={loginId && loginId.length > 0 ? 'block' : 'hidden'}>
                <WizIconButton className={'p-[4px]'}>
                    <MoreIcon className={'w-[16px] fill-gray01 dark:fill-dark_gray01'} />
                </WizIconButton>
            </WizCustomMenu>

            <DeleteCommentPopupModal ref={deleteCommentRef} type={'reply'} id={id} parent_id={parent_id} />
            <ReportPostPopupModal ref={reportCommentRef} />
        </div>
    )
}
export default ReplyItemHeader
