import React from 'react'
import {useTranslation} from 'next-i18next'

import useQueryGetTrending from '../../../../../src/hooks/query/announce/useQueryGetTrending'
import Text from '../../../../../src/components/Text/Text'
import TitleAndContentComponent from './TitleAndContentComponent'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
}

const NEWS_LIMIT = 5
const SideBreakingComponent: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {trend, isLoading} = useQueryGetTrending(NEWS_LIMIT)

    if (isLoading) {
        return (
            <div className={`flex flex-col ${className}`}>
                <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                    {t('timeline.sidebar.hourly.trends.title')}
                </Text>
                <div className={'w-full flex flex-col gap-y-[20px] my-[10px]'}>
                    {Array.from({length: NEWS_LIMIT}).map((_, index) => {
                        return (
                            <SkeletonBlock
                                key={index.toString()}
                                className={'w-full h-[39px] mx-auto'}
                                sizeClassName={'h-[39px]'}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className={`${className}`}>
            <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                {t('timeline.sidebar.breaking.title')}
            </Text>
            {trend?.map(news => {
                return (
                    <TitleAndContentComponent
                        key={news?.id}
                        title={news?.title}
                        author={news?.owner?.name}
                        created_at={news?.created_at}
                        link={news?.link}
                        target={'_blank'}
                    />
                )
            })}
        </div>
    )
}
export default SideBreakingComponent
