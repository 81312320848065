import React, {useRef} from 'react'
import {useTranslation} from 'next-i18next'

import {IMAGE_ACCEPT} from '@util/image'
import {apiUploadImage} from '@api/core/image/uploadImage'
import {showToast} from '@component/snackbar/WizToastMessage'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import Spinner from '@component/Spinner/Spinner'
import TimelineMobileEditorErrorModal from '../../../../../post/modals/TimelineMobileEditorErrorModal'

import ImageIcon from '@svg/timeline/ic_btn_image.svg'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string
    images?: string[]
    setImages: (images?: string[]) => void
    setHasImageError?: (hasError: boolean) => void
}

const TimelineEditorImageOperation: React.FC<IProps> = ({
    className,
    id = 'fileinput',
    disabled,
    images,
    setImages,
    setHasImageError,
}) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = React.useState(false)

    const errorModalRef = useRef(null)

    const hasImageLengthError = () => {
        if (!images || images.length < 5) return false
        if (setHasImageError) {
            setHasImageError(true)
            return true
        }
        errorModalRef.current?.show({error: t('timeline.editor.post.image.limit.error')})
        return true
    }

    const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e?.stopPropagation()
        e?.preventDefault()
        createLog('event', 'timeline_click_editor_image')

        if (isLoading || disabled) return
        if (hasImageLengthError()) return

        const file = e?.target?.files[0]
        if (!file) return
        if (file.size > 1500 * 1500) {
            showToast(t('file.image.size.limit.text'))
            e.target.value = ''
            return
        }

        setIsLoading(true)
        const {success, image_url} = await apiUploadImage(file)

        if (success) {
            setImages([...(images || []), image_url])
        }

        e.target.value = ''
        setIsLoading(false)
    }

    return (
        <div>
            <label htmlFor={id} className={'cursor-pointer'}>
                <div
                    className={`flex items-center gap-x-[5px] px-[10px] py-[7px] border border-transparent hover:border-gray06 hover:dark:border-dark_gray06 rounded-[3px] cursor-pointer ${className}`}>
                    {isLoading ? (
                        <Spinner customSize={12} visible={true} size={'smallest'} />
                    ) : (
                        <ImageIcon className={'fill-gray01 dark:fill-dark_gray01'} />
                    )}

                    <Text className={'text-body2 text-gray01 dark:text-dark_gray01'}>
                        {t('timeline.editor.image.button.text')}
                    </Text>
                </div>
            </label>

            <input
                id={id}
                disabled={disabled}
                type={'file'}
                accept={IMAGE_ACCEPT}
                onChange={onChangeImage}
                style={{display: 'none'}}
            />

            <TimelineMobileEditorErrorModal ref={errorModalRef} />
        </div>
    )
}
export default TimelineEditorImageOperation
