import React from 'react'

import WizImg from '@component/images/WizImg'

import DeleteIcon from '@svg/common/ic_close.svg'

interface IProps {
    className?: string
    images?: string[]
    setImages: (images?: string[]) => void
    hasImageError?: boolean
    setHasImageError?: (hasError: boolean) => void
}

const TimelineEditorImages: React.FC<IProps> = ({className, images, setImages, hasImageError, setHasImageError}) => {
    if (!images || images.length === 0) return null

    const onClickDeleteImage = (image: string) => {
        setImages(images.filter(e => e !== image))

        if (!hasImageError) return
        setHasImageError?.(false)
    }
    return (
        <div className={`flex flex-col items-center gap-y-[3px] py-[10px] ${className}`}>
            {images.map(image => {
                return (
                    <div key={image} className={'relative'}>
                        <WizImg src={image} className={'max-h-[200px]'} />
                        <span
                            onClick={() => {
                                onClickDeleteImage(image)
                            }}
                            className={
                                'absolute -top-[3px] -right-[3px] w-[20px] h-[20px] p-[6px] bg-gray02 dark:bg-dark_gray02 rounded-full cursor-pointer'
                            }>
                            <DeleteIcon className={'fill-white dark:fill-bg_dark_background'} />
                        </span>
                    </div>
                )
            })}
        </div>
    )
}
export default TimelineEditorImages
