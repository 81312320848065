import React, {useCallback} from 'react'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'

import {TWriter, WritePostParams} from '@api/timeline/type'
import useLogin from '@hook/useLogin'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginModalStore} from '@store/LoginModalStore'
import {useTimelineStore} from '../../../../store/useTimelineStore'
import {useTimelineCreateStore} from '../../../../store/useTimelineCreateStore'
import {useTimelineChannel} from '../../../../store/useTimelineChannel'

import {useMutationCreatePost} from '@hook/query/timeline/useMutationCreatePost'
import {showToast} from '@component/snackbar/WizToastMessage'

import Text from '@component/Text/Text'

interface IProps {
    className?: string
}

const TimelineEditorPostButton: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {isLogin} = useLogin()
    const {data: me} = useQueryFetchMe()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {visibility} = useTimelineStore(state => state)
    const {channel} = useTimelineChannel()
    const {title, content, images, setTitle, setContent, setImages, setHasError} = useTimelineCreateStore(
        state => state,
    )

    const {mutateAsync} = useMutationCreatePost()

    const onClickPost = useCallback(async () => {
        if (!isLogin()) {
            showLoginModal()
            return
        }
        if (!title) {
            setHasError(true)
            return
        }
        if (title && !content && (!images || images.length === 0)) {
            setHasError(true)
            return
        }
        const writer: TWriter = {
            id: me?.id,
            name: me?.nicknames?.find(e => e.language === channel)?.nickname ?? me?.name,
            thumbnail: me?.thumbnail,
            is_pro: me?.is_pro,
            status: me?.status,
        }

        const post: WritePostParams = {
            type: 'post',
            title,
            content,
            images,
            channel: channel === 'all' ? 'en' : channel,
            visibility,
            writer,
        }

        await mutateAsync(post, {
            onSuccess: ({data}) => {
                setTitle('')
                setContent('')
                setImages([])
                showToast(t('timeline.success.post.alert.title'))
                if (data?.point) {
                    showToast(t('timeline.like.point.alert.title', {point: data?.point}))
                }
            },
        })
    }, [title, content, images, channel, visibility, me])

    return (
        <span
            className={classNames(
                'px-[20px] py-[12px] cursor-pointer -mr-[1px]',
                title && (content || (images && images.length > 0))
                    ? 'bg-primary dark:bg-dark_primary'
                    : 'bg-gray07 dark:bg-dark_gray07',
                className,
            )}
            onClick={onClickPost}>
            <Text
                className={classNames(
                    'text-btn',
                    title && (content || (images && images.length > 0))
                        ? 'text-white'
                        : 'text-gray04 dark:text-dark_gray04',
                )}>
                {t('timeline.editor.post.button.text')}
            </Text>
        </span>
    )
}
export default TimelineEditorPostButton
