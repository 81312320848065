import React from 'react'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const MAX_TITLE_LENGTH = 100
const TimeLineTitleInput: React.FC<IProps> = ({className, maxLength = MAX_TITLE_LENGTH, ...props}) => {
    const {t} = useTranslation()
    return (
        <input
            className={classNames(
                'w-full p-[10px] sm:p-[5px] min-h-[24px] bg-transparent',
                'text-ti1 text-gray01 dark:text-dark_gray01',
                'border-b border-gray07 dark:border-dark_gray07',
                'peer',
                'placeholder:text-gray04 dark:placeholder:text-dark_gray04',
                className,
            )}
            placeholder={t('timeline.editor.input.title.placeholder')}
            maxLength={maxLength}
            {...props}
        />
    )
}
export default TimeLineTitleInput
