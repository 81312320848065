import React from 'react'
import TimelineCommentListContainer from './TimelineCommentListContainer'
import TimelineDetailEditorComponent from '../components/detail/TimelineDetailEditorComponent'

interface IProps {
    className?: string
    postId?: string
}

const TimelineCommentContainer: React.FC<IProps> = ({className, postId}) => {
    return (
        <div className={`${className}`}>
            <TimelineDetailEditorComponent postId={postId} />
            <TimelineCommentListContainer postId={postId} />
        </div>
    )
}
export default TimelineCommentContainer
