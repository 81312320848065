import React from 'react'
import useFormatNumber from '@hook/useFormatNumber'
import {MAX_CONTENT_LENGTH} from './items/TimelineContentTextArea'
import Text from '@component/Text/Text'
import TimelineEditorPostButton from './items/TimelineEditorPostButton'
import classNames from 'classnames'

interface IProps {
    className?: string
    content?: string
    enablePostButton?: boolean
}

const TimelineEditorOtherOperations: React.FC<IProps> = ({className, content, enablePostButton = true}) => {
    const {displayNumber} = useFormatNumber()

    return (
        <div className={`flex items-center ${className}`}>
            <span className={'flex items-center mr-[10px] py-[12px]'}>
                <Text
                    className={classNames(
                        'text-ti2',
                        content?.length >= MAX_CONTENT_LENGTH
                            ? 'text-red_shade dark:text-dark_red_shade'
                            : 'text-gray01 dark:text-dark_gray01',
                    )}>
                    {displayNumber(content?.length || 0)}
                </Text>
                <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>
                    /{displayNumber(MAX_CONTENT_LENGTH)}
                </Text>
            </span>
            {enablePostButton && <TimelineEditorPostButton />}
        </div>
    )
}
export default TimelineEditorOtherOperations
