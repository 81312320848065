import React, {useMemo} from 'react'
import {TComment} from '@api/timeline/type'
import {urlValidation} from '@util/validation'
import {parsingUrl} from '@util/strings'
import TagText from '@component/Text/TagText'

interface IProps extends Pick<TComment, 'content'> {
    className?: string
}

const CommentItemContents: React.FC<IProps> = ({className, content}) => {
    const contentText = useMemo(() => {
        let content_ = content
        // parse url
        if (urlValidation(content_)) {
            content_ = parsingUrl(content_)
        }

        return content_
    }, [content])

    return (
        <TagText className={`whitespace-pre-wrap text-body1 text-gray01 dark:text-dark_gray01 break-all ${className}`}>
            {contentText || ''}
        </TagText>
    )
}
export default CommentItemContents
