import React from 'react'
import classNames from 'classnames'

import TimelineDetailOperations from './TimelineDetailOperations'
import TimelineDetailHeader from './TimelineDetailHeader'
import TimelineDetailContents from './TimelineDetailContents'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailContentsComponent: React.FC<IProps> = ({className, postId}) => {
    return (
        <div
            className={classNames(
                `flex flex-col gap-y-[10px] px-[20px] pt-[20px] pb-[10px]`,
                'border border-gray07 dark:border-dark_gray07 rounded-[5px] sm:border-none',
                // 'hover:bg-gray09 dark:hover:bg-dark_gray09',
                className,
            )}>
            <TimelineDetailHeader postId={postId} />
            <TimelineDetailContents postId={postId} />
            <TimelineDetailOperations postId={postId} />
        </div>
    )
}
export default TimelineDetailContentsComponent
