import React from 'react'
import {useTranslation} from 'next-i18next'

import {TPost} from '@api/timeline/type'
import {createLog} from '@util/logs'

import useLogin from '@hook/useLogin'
import {useLoginModalStore} from '@store/LoginModalStore'
import {useMutationLikePost} from '@hook/query/timeline/useMutationLikePost'
import {showToast} from '@component/snackbar/WizToastMessage'

import Text from '@component/Text/Text'

import ViewCountIcon from '@svg/timeline/ic_view_count.svg'
import LikeIcon from '@svg/timeline/ic_heart.svg'
import CommentIcon from '@svg/timeline/ic_comment.svg'

interface IProps extends Pick<TPost, 'id' | 'user_id' | 'views' | 'is_liked' | 'likes_count'> {}

const TimelineMainOperations: React.FC<IProps> = ({id, user_id, views, is_liked, likes_count}) => {
    const {t} = useTranslation()
    const {isLogin} = useLogin()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const {mutateAsync, isLoading} = useMutationLikePost()
    const onClickLike = async (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.preventDefault()
        e?.stopPropagation()
        if (!isLogin()) {
            showLoginModal()
            return
        }

        createLog('event', 'timeline_click_like')
        await mutateAsync(
            {is_liked, parent_id: id, user_id},
            {
                onSuccess: ({data}) => {
                    if (!is_liked && data?.point) {
                        showToast(t('timeline.like.point.alert.title', {point: data?.point}))
                    }
                },
            },
        )
    }
    return (
        <div className={'flex items-center gap-x-[10px] sm:gap-x-0'}>
            <div className={'flex items-center gap-x-[5px] p-[10px]'}>
                <ViewCountIcon className={'fill-gray02 dark:fill-dark_gray02'} />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.view.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{views}</Text>
            </div>
            <div className={'flex items-center gap-x-[5px] p-[10px] cursor-pointer'} onClick={onClickLike}>
                <LikeIcon
                    className={
                        is_liked
                            ? 'fill-red dark:fill-dark_red stroke-red dark:stroke-dark_red'
                            : 'stroke-gray02 dark:stroke-dark_gray02'
                    }
                />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.like.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{likes_count}</Text>
            </div>
            <div className={'flex items-center gap-x-[5px] p-[10px]'}>
                <CommentIcon className={'fill-gray02 dark:fill-dark_gray02'} />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:hidden'}>
                    {t('timeline.item.comment.title')}
                </Text>
            </div>
        </div>
    )
}
export default TimelineMainOperations
