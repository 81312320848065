import React, {useMemo} from 'react'

import {useQueryGetPost} from '@hook/query/timeline/useQueryGetPost'

import Text from '@component/Text/Text'
import TimelineDetailImage from './TimelineDetailImage'
import TimelineDetailContentsTranslate from './TimelineDetailContentsTranslate'
import {getURLs, urlValidation} from '@util/validation'
import TagText from '@component/Text/TagText'
import {parsingUrl} from '@util/strings'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailContents: React.FC<IProps> = ({className, postId}) => {
    const {data} = useQueryGetPost(postId)

    const [isTranslated, setIsTranslated] = React.useState(false)
    const [translatedText, setTranslatedText] = React.useState('')

    const contentText = useMemo(() => {
        let content = isTranslated ? translatedText : data?.content

        if (urlValidation(content)) {
            content = parsingUrl(content)
        }

        return content
    }, [data?.content, translatedText, isTranslated])

    return (
        <div className={`flex flex-col gap-y-[10px] ml-[50px] sm:ml-0 ${className}`}>
            {/* title */}
            <Text className={'text-h3 text-gray01 dark:text-dark_gray01 break-all'}>{data?.title}</Text>

            {/* web image */}
            <TimelineDetailImage images={data?.images} className={'sm:hidden'} />

            {/* content */}
            <TagText className={'whitespace-pre-wrap text-body1 text-gray01 dark:text-dark_gray01 break-all'}>
                {contentText || ''}
            </TagText>

            {/* translate button */}
            <TimelineDetailContentsTranslate
                postId={postId}
                content={data?.content}
                language={data?.language}
                isTranslated={isTranslated}
                setIsTranslated={setIsTranslated}
                setTranslatedText={setTranslatedText}
            />

            {/* mobile image */}
            <TimelineDetailImage images={data?.images} className={'hidden sm:block'} />
        </div>
    )
}
export default TimelineDetailContents
