import React from 'react'

import {TPost} from '@api/timeline/type'

import Text from '@component/Text/Text'
import WizImg from '@component/images/WizImg'
import TimelineItemContentItem from './TimelineItemContentItem'

interface IProps extends Pick<TPost, 'title' | 'content' | 'images'> {
    className?: string
}

const TimelineItemContents: React.FC<IProps> = ({className, title, content, images}) => {
    return (
        <div className={`flex flex-col gap-y-[10px] pl-[40px] cursor-pointer ${className}`}>
            <div>
                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 break-all'}>{title}</Text>
                <TimelineItemContentItem content={content} />
            </div>

            {images?.at(0) ? (
                <div className={'mx-auto sm:mx-0'}>
                    <WizImg src={images?.at(0)} className={'max-h-[200px]'} />
                </div>
            ) : null}
        </div>
    )
}
export default React.memo(TimelineItemContents)
