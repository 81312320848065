import React, {useRef} from 'react'
import WizImg from '@component/images/WizImg'
import WizImageViewer, {TWizImageViewerRef} from '@component/images/WizImageViewer'

interface IProps {
    className?: string
    images?: string[]
}

const TimelineDetailImage: React.FC<IProps> = ({className, images}) => {
    const [index, setImageIndex] = React.useState(0)
    const imageViewerRef = useRef<null | TWizImageViewerRef>()

    const onClickImage = (index: number) => {
        setImageIndex(index)
        imageViewerRef.current?.show()
    }

    if (!images || images.length === 0) return null
    return (
        <>
            {images.map((image, index) => {
                return (
                    <div
                        key={image}
                        className={`mx-auto cursor-pointer ${className}`}
                        onClick={() => onClickImage(index)}>
                        <WizImg src={image} />
                    </div>
                )
            })}
            <WizImageViewer ref={imageViewerRef} image={images?.at(index)} />
        </>
    )
}
export default TimelineDetailImage
