import React from 'react'
import SideHourlyTrendsComponent from '../components/side/SideHourlyTrendsComponent'
import SideAnnouncementsComponent from '../components/side/SideAnnouncementsComponent'
import SideBreakingComponent from '../components/side/SideBreakingComponent'

interface IProps {
    className?: string
}

const TimelineSideContentsContainer: React.FC<IProps> = ({className}) => {
    return (
        <article className={`w-full h-full flex flex-col gap-y-[40px] ${className}`}>
            <SideHourlyTrendsComponent />
            <SideAnnouncementsComponent />
            <SideBreakingComponent />
        </article>
    )
}
export default TimelineSideContentsContainer
