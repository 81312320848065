import React, {useImperativeHandle, useRef} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'
import {useMutationDeletePost} from '@hook/query/timeline/useMutationDeletePost'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'

interface IProps {
    type?: 'list' | 'detail'
}

const DeletePostPopupModal = ({type = 'list'}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()

    const [id, setId] = React.useState<string>()
    const [userId, setUserId] = React.useState<string>()

    const {mutateAsync} = useMutationDeletePost()

    useImperativeHandle(ref, () => ({
        show: ({id, userId}: {id: string; userId: string}) => {
            modalRef.current.show()
            setId(id)
            setUserId(userId)
        },
        close: () => {},
    }))

    const onClose = () => {
        modalRef.current.close()
        setId(undefined)
        setUserId(undefined)
    }

    const onDelete = async () => {
        if (!id || !userId) return
        createLog('event', 'timeline_click_delete_post')
        await mutateAsync(
            {post_id: id, user_id: userId},
            {
                onSuccess: () => {
                    onClose()
                    if (type === 'list') return
                    router?.back()
                },
            },
        )
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.delete.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[10px] mb-[20px]'}>
                {t('timeline.delete.modal.desc')}
            </Text>
            <div className={'flex items-center gap-x-[5px]'}>
                <WizButton
                    text={t('timeline.modal.cancel.button.text')}
                    buttonType={'border'}
                    className={'w-full'}
                    onClick={onClose}
                />
                <WizButton
                    text={t('timeline.delete.modal.button.text')}
                    buttonType={'red'}
                    className={'w-full'}
                    onClick={onDelete}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(DeletePostPopupModal)
