import React, {useMemo} from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {useQueryGetPost} from '@hook/query/timeline/useQueryGetPost'

import {URL_PORTFOLIO, URL_TIMELINE_USER} from '@constant/url'
import useFormatDate from '@hook/useFormatDate'
import useLogin from '@hook/useLogin'
import useQueryFollowing from '@hook/query/user/account/subscriber/useQueryFollowing'
import {useMutationFollowUnfollow} from '@hook/query/timeline/useMutationFollowUnfollow'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import UserThumbnail from '@component/images/UserThumbnail'
import ProTag from '@component/tags/ProTag'

import ClockIcon from '@svg/timeline/ic_clock.svg'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailHeader: React.FC<IProps> = ({className, postId}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {getRelativeTime} = useFormatDate()

    const {data} = useQueryGetPost(postId, true)
    const {id, isMe, isLogin} = useLogin(data?.user_id)
    const {data: following, refresh} = useQueryFollowing(id)
    const {mutateAsync} = useMutationFollowUnfollow()

    const isFollowing = useMemo(
        () => following?.map(e => e.user_id)?.includes(data?.user_id),
        [following, data?.user_id],
    )

    const routeToPortfolio = () => {
        createLog('event', 'timeline_detail_click_portfolio')
        router.push(URL_PORTFOLIO(data?.user?.id))
    }

    const onClickFollowUnfollow = async () => {
        createLog('event', 'timeline_detail_click_follow')
        await mutateAsync(
            {isFollowing, user_id: data?.user_id},
            {
                onSuccess: async ({data}) => {
                    if (!data?.success) return
                    await refresh()
                },
            },
        )
    }

    return (
        <div className={`flex items-center justify-between gap-x-[10px] ${className}`}>
            <Link href={URL_TIMELINE_USER(data?.user_id)}>
                <div className={'flex items-center justify-between gap-x-[10px] cursor-pointer'}>
                    <UserThumbnail src={data?.user?.thumbnail} size={40} />
                    <div className={'flex-1'}>
                        <div className={'flex items-center gap-x-[3px]'}>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 mb-[3px]'}>
                                {data?.user?.name}
                            </Text>
                            {data?.user?.is_pro && <ProTag size={'small'} />}
                        </div>
                        <div className={'flex items-center gap-x-[5px]'}>
                            <ClockIcon className={'fill-gray03 dark:fill-dark_gray03'} />
                            <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>
                                {getRelativeTime(data?.created_at)}
                            </Text>
                        </div>
                    </div>
                </div>
            </Link>
            <div className={'flex items-center gap-x-[5px]'}>
                {/* web portfolio button */}
                {!data?.user?.is_official && (
                    <WizButton
                        className={'sm:hidden'}
                        text={t('timeline.item.header.portfolio.button.text')}
                        buttonType={'border'}
                        size={'small'}
                        onClick={routeToPortfolio}
                    />
                )}

                {/* mobile portfolio button */}
                {!data?.user?.is_official && (
                    <WizButton
                        className={'hidden sm:block'}
                        text={t('timeline.item.header.portfolio.button.text')}
                        buttonType={'border'}
                        size={'smallest'}
                        onClick={routeToPortfolio}
                    />
                )}

                {/* web follow button */}
                {isLogin() && !isMe && !data?.user?.is_official && isFollowing !== undefined ? (
                    <WizButton
                        className={'sm:hidden'}
                        text={
                            isFollowing
                                ? t('timeline.item.header.unfollow.button.text')
                                : '+ ' + t('timeline.item.header.follow.button.text')
                        }
                        buttonType={isFollowing ? 'border' : 'primary'}
                        size={'small'}
                        onClick={onClickFollowUnfollow}
                    />
                ) : null}

                {/* mobile follow button */}
                {isLogin() && !isMe && !data?.user?.is_official && isFollowing !== undefined ? (
                    <WizButton
                        className={'hidden sm:block'}
                        text={
                            isFollowing
                                ? t('timeline.item.header.unfollow.button.text')
                                : '+ ' + t('timeline.item.header.follow.button.text')
                        }
                        buttonType={isFollowing ? 'border' : 'primary'}
                        size={'smallest'}
                        onClick={onClickFollowUnfollow}
                    />
                ) : null}
            </div>
        </div>
    )
}
export default TimelineDetailHeader
