import React from 'react'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'
import {MAX_CONTENT_LENGTH} from '../../features/components/timeline/editor/items/TimelineContentTextArea'

interface IProps {
    className?: string
    content?: string
}

const TimelineMobilePostContentLength = ({className, content}: IProps) => {
    const {displayNumber} = useFormatNumber()
    return (
        <span
            className={
                'flex items-center justify-end py-[5px] px-[10px] border-b border-gray07 dark:border-dark_gray07'
            }>
            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>{content?.length || 0}</Text>
            <Text className={'text-body2 text-gray03 dark:text-dark_gray03'}>/{displayNumber(MAX_CONTENT_LENGTH)}</Text>
        </span>
    )
}
export default TimelineMobilePostContentLength
