import React from 'react'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'

import TextareaAutosize from 'react-textarea-autosize'
import {TextareaAutosizeProps} from 'react-textarea-autosize/dist/declarations/src'

interface IProps extends TextareaAutosizeProps, React.RefAttributes<HTMLTextAreaElement> {}

export const MAX_CONTENT_LENGTH = 2000
const TimelineContentTextArea: React.FC<IProps> = ({className, maxLength = MAX_CONTENT_LENGTH, ...props}) => {
    const {t} = useTranslation()
    return (
        <TextareaAutosize
            className={classNames(
                'w-full p-[10px] sm:p-[5px] min-h-[42px] bg-transparent',
                'text-body2 text-gray01 dark:text-dark_gray01 resize-none',
                'placeholder:text-gray04 dark:placeholder:text-dark_gray04 placeholder:disabled:text-gray05 dark:placeholder:disabled:text-dark_gray05',
                className,
            )}
            placeholder={t('timeline.editor.input.content.placeholder')}
            maxLength={maxLength}
            {...props}
        />
    )
}
export default TimelineContentTextArea
