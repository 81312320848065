import React from 'react'
import TimelineDetailContainer from './TimelineDetailContainer'
import TimelineSideContentsContainer from '../../features/containers/TimelineSideContentsContainer'

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailMainContainer: React.FC<IProps> = ({className, postId}) => {
    return (
        <main className={'layout-desktop overflow-hidden min-h-screen default_page_padding mt-[20px] sm:mt-0 sm:px-0'}>
            <section className={'w-full h-full grid grid-cols-[auto_340px] gap-x-[30px] md:grid-cols-1'}>
                <TimelineDetailContainer postId={postId} />
                <TimelineSideContentsContainer className={'md:hidden'} />
            </section>
        </main>
    )
}
export default TimelineDetailMainContainer
