import React from 'react'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import UserThumbnail from '@component/images/UserThumbnail'
import TimelineCommentEditor from './TimelineCommentEditor'

interface IProps {
    className?: string
    id?: string
    parent_id?: string
}

const TimelineCommentEditorComponent: React.FC<IProps> = ({className, id, parent_id}) => {
    const {data: me} = useQueryFetchMe(undefined, false)

    return (
        <div
            className={`flex gap-x-[10px] px-[20px] py-[15px] border-b border-gray08 dark:border-dark_gray08 ${className}`}>
            <UserThumbnail src={me?.thumbnail} size={30} />
            <TimelineCommentEditor id={id} parent_id={parent_id} />
        </div>
    )
}
export default TimelineCommentEditorComponent
