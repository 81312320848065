import {create} from 'zustand'

type TimelineCreateState = {
    title?: string
    content?: string
    images?: string[]
    hasError: boolean
    hasImageError: boolean

    setTitle: (title?: string) => void
    setContent: (content?: string) => void
    setImages: (images?: string[]) => void
    setHasError: (hasError: boolean) => void
    setHasImageError: (hasError: boolean) => void
}
export const useTimelineCreateStore = create<TimelineCreateState>(set => ({
    hasError: false,
    hasImageError: false,
    setTitle: title => set({title}),
    setContent: content => set({content}),
    setImages: images => set({images}),
    setHasError: hasError => set({hasError}),
    setHasImageError: hasImageError => set({hasImageError}),
}))
