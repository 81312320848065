import React from 'react'
import Link from 'next/link'

import {TComment} from '@api/timeline/type'
import {URL_TIMELINE_USER} from '@constant/url'

import UserThumbnail from '@component/images/UserThumbnail'
import CommentItemHeader from './CommentItemHeader'
import CommentItemContents from './CommentItemContents'
import CommentItemOperations from './CommentItemOperations'
import TimelineReplyListComponent from '../../reply/TimelineReplyListComponent'

interface IProps extends TComment {
    className?: string
}

const CommentListItem: React.FC<IProps> = ({
    className,
    id,
    parent_id,
    user,
    created_at,
    content,
    is_liked,
    likes_count,
}) => {
    return (
        <React.Fragment>
            <div className={`flex gap-x-[10px] ${className}`}>
                <Link href={URL_TIMELINE_USER(user?.id)}>
                    <UserThumbnail src={user?.thumbnail} size={30} />
                </Link>
                <div className={'w-full flex flex-col gap-y-[5px]'}>
                    <CommentItemHeader id={id} parent_id={parent_id} user={user} created_at={created_at} />
                    <CommentItemContents content={content} />
                    <CommentItemOperations
                        id={id}
                        parent_id={parent_id}
                        user={user}
                        is_liked={is_liked}
                        likes_count={likes_count}
                    />
                </div>
            </div>
            <TimelineReplyListComponent parentId={id} />
        </React.Fragment>
    )
}
export default CommentListItem
