import React from 'react'
import {useTranslation} from 'next-i18next'

import {useLoginStore} from '@store/LoginStore'
import {useLoginModalStore} from '@store/LoginModalStore'
import {useCommentStore} from '../../store/useCommentStore'

import {TComment} from '@api/timeline/type'
import {useMutationLikeComment} from '@hook/query/timeline/useMutationLikeComment'
import {useQueryGetReplies} from '@hook/query/timeline/useQueryGetReplies'
import {createLog} from '@util/logs'

import Text from '@component/Text/Text'
import TimelineCommentEditorComponent from '../../comment/TimelineCommentEditorComponent'

import LikeIcon from '@svg/timeline/ic_heart.svg'
import CommentIcon from '@svg/timeline/ic_comment.svg'

interface IProps extends Pick<TComment, 'id' | 'parent_id' | 'user' | 'is_liked' | 'likes_count'> {
    className?: string
}

const ReplyItemOperations: React.FC<IProps> = ({className, id, parent_id, user, is_liked, likes_count}) => {
    const {t} = useTranslation()
    const {id: loginId} = useLoginStore()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {commentInfo, setCommentInfo} = useCommentStore()

    const {refetch} = useQueryGetReplies(parent_id)
    const {mutateAsync} = useMutationLikeComment()

    const checkLogin = () => {
        if (loginId && loginId.length > 0) return true
        showLoginModal()
    }

    const onClickLike = async () => {
        if (!checkLogin()) return
        createLog('event', 'timeline_detail_click_reply_like')
        await mutateAsync({is_liked, parent_id: id}, {onSuccess: async () => await refetch()})
    }

    const onClickComment = () => {
        if (!checkLogin()) return
        createLog('event', 'timeline_detail_click_reply')
        setCommentInfo({id: user?.id, name: user?.name, commentId: id})
    }

    return (
        <React.Fragment>
            <div className={`flex items-center gap-x-[20px] py-[5px] ${className}`}>
                <div className={'flex items-center gap-x-[5px] cursor-pointer'} onClick={onClickLike}>
                    <LikeIcon
                        className={
                            is_liked
                                ? 'fill-red dark:fill-dark_red stroke-red dark:stroke-dark_red'
                                : 'stroke-gray02 dark:stroke-dark_gray02'
                        }
                    />
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('timeline.item.like.title')}
                    </Text>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{likes_count}</Text>
                </div>
                <div className={'flex items-center gap-x-[5px] cursor-pointer'} onClick={onClickComment}>
                    <CommentIcon className={'fill-gray02 dark:fill-dark_gray02'} />
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('timeline.item.comment.title')}
                    </Text>
                </div>
            </div>
            {commentInfo?.commentId === id && loginId && loginId.length > 0 ? (
                <TimelineCommentEditorComponent className={'!px-[0px]'} parent_id={parent_id} />
            ) : null}
        </React.Fragment>
    )
}
export default ReplyItemOperations
