import React from 'react'
import dynamic from 'next/dynamic'
import TimelineDetailMainOperations from './TimelineDetailMainOperations'
const TimelineDetailOtherOperations = dynamic(import('./TimelineDetailOtherOperations'), {
    ssr: false,
})

interface IProps {
    className?: string
    postId?: string
}

const TimelineDetailOperations: React.FC<IProps> = ({className, postId}) => {
    return (
        <div className={`flex items-center justify-between ${className}`}>
            <TimelineDetailMainOperations postId={postId} />
            <TimelineDetailOtherOperations postId={postId} />
        </div>
    )
}
export default TimelineDetailOperations
