import React from 'react'
import Link from 'next/link'

import {TComment} from '@api/timeline/type'
import {URL_TIMELINE_USER} from '@constant/url'

import UserThumbnail from '@component/images/UserThumbnail'
import ReplyItemContents from './ReplyItemContents'
import ReplyItemHeader from './ReplyItemHeader'
import ReplyItemOperations from './ReplyItemOperations'

interface IProps extends TComment {
    className?: string
}

const ReplyListItem: React.FC<IProps> = ({
    className,
    id,
    parent_id,
    user,
    created_at,
    content,
    is_liked,
    likes_count,
    mentioned_username,
    mention_user_id,
}) => {
    return (
        <div className={`flex gap-x-[10px] ${className}`}>
            <Link href={URL_TIMELINE_USER(user?.id)}>
                <UserThumbnail src={user?.thumbnail} size={30} />
            </Link>
            <div className={'w-full flex flex-col gap-y-[5px]'}>
                <ReplyItemHeader id={id} parent_id={parent_id} user={user} created_at={created_at} />
                <ReplyItemContents
                    content={content}
                    mention_user_id={mention_user_id}
                    mentioned_username={mentioned_username}
                />
                <ReplyItemOperations
                    id={id}
                    parent_id={parent_id}
                    user={user}
                    is_liked={is_liked}
                    likes_count={likes_count}
                />
            </div>
        </div>
    )
}
export default ReplyListItem
