import React, {useImperativeHandle, useRef} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {TWriter} from '@api/timeline/type'
import {createLog} from '@util/logs'
import {useMutationBanUser} from '@hook/query/ban/useMutationBanUser'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'

interface IProps {
    type?: 'list' | 'detail'
}

const BlockUserPopupModal = ({type = 'list'}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()

    const [user, setUser] = React.useState<TWriter>()

    const {mutateAsync} = useMutationBanUser()

    useImperativeHandle(ref, () => ({
        show: ({user}: {user: TWriter}) => {
            modalRef.current.show()
            setUser(user)
        },
        close: () => {},
    }))

    const onClose = () => {
        modalRef.current.close()
        setUser(undefined)
    }

    const onBlock = async () => {
        if (!user) return
        createLog('event', 'timeline_click_block_user')
        await mutateAsync(user?.id, {
            onSuccess: () => {
                if (type === 'list') return
                router?.back()
            },
        })
        onClose()
    }

    return (
        <WizContentModal
            ref={modalRef}
            title={t('timeline.block.modal.title')}
            panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}
            titleClassName={'text-ti1'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[10px] mb-[20px]'}>
                {t('timeline.block.modal.desc', {name: user?.name})}
            </Text>
            <div className={'flex items-center gap-x-[5px]'}>
                <WizButton
                    text={t('timeline.modal.cancel.button.text')}
                    buttonType={'border'}
                    className={'w-full'}
                    onClick={onClose}
                />
                <WizButton
                    text={t('timeline.block.modal.button.text')}
                    buttonType={'red'}
                    className={'w-full'}
                    onClick={onBlock}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(BlockUserPopupModal)
