import {create} from 'zustand'
import {TWriter} from '@api/timeline/type'

interface ICommentStore {
    commentInfo?: TWriter & {commentId: string}
    setCommentInfo: (commentInfo: TWriter & {commentId: string}) => void
}

export const useCommentStore = create<ICommentStore>(set => ({
    setCommentInfo: commentInfo => set({commentInfo}),
}))
