import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'

import {TPost} from '@api/timeline/type'

import {URL_TIMELINE_DETAIL} from '@constant/url'
import {createLog} from '@util/logs'
import {TTimelineOperatorMethod} from '@pages/timeline/features/components/timeline/list/TimelineListComponent'

import TimelineItemHeader from './TimelineItemHeader'
import TimelineItemContents from './TimelineItemContents'
import TimelineItemOperations from './TimelineItemOperations'

interface IProps extends TPost {
    className?: string
    isFollowing?: boolean
    onClickOperations?: TTimelineOperatorMethod
}

const TimelineListItem: React.FC<IProps> = ({
    className,
    id,
    user,
    created_at,
    title,
    content,
    images,
    views,
    is_liked,
    likes_count,
    isFollowing,
    onClickOperations,
}) => {
    return (
        <Link href={URL_TIMELINE_DETAIL(id)} onClick={() => createLog('event', 'timeline_click_post')}>
            <div
                className={classNames(
                    `flex flex-col gap-y-[10px] px-[20px] pt-[20px] pb-[10px]`,
                    'border border-gray07 dark:border-dark_gray07 rounded-[5px] sm:border-t-0 sm:border-l-0 sm:border-r-0 sm:rounded-none',
                    'hover:bg-gray09 dark:hover:bg-dark_gray09',
                    className,
                )}>
                <TimelineItemHeader user={user} created_at={created_at} isFollowing={isFollowing} />
                <TimelineItemContents title={title} content={content} images={images} />
                <TimelineItemOperations
                    id={id}
                    user={user}
                    views={views}
                    is_liked={is_liked}
                    likes_count={likes_count}
                    onClickOperations={onClickOperations}
                />
            </div>
        </Link>
    )
}
export default TimelineListItem
