import React from 'react'
import {useTranslation} from 'next-i18next'

import {URL_ANNOUNCE_POST} from '@constant/url'
import useQueryGetAnnouncements from '@hook/query/announce/useQueryGetAnnouncements'

import Text from '@component/Text/Text'
import TitleAndContentComponent from './TitleAndContentComponent'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
}

const ANNOUNCEMENT_LIMIT = 5
const SideAnnouncementsComponent: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {announcements, isLoading} = useQueryGetAnnouncements(ANNOUNCEMENT_LIMIT)

    if (isLoading) {
        return (
            <div className={`flex flex-col ${className}`}>
                <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                    {t('timeline.sidebar.hourly.trends.title')}
                </Text>
                <div className={'w-full flex flex-col gap-y-[20px] my-[10px]'}>
                    {Array.from({length: ANNOUNCEMENT_LIMIT}).map((_, index) => {
                        return (
                            <SkeletonBlock
                                key={index.toString()}
                                className={'w-full h-[39px] mx-auto'}
                                sizeClassName={'h-[39px]'}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className={`${className}`}>
            <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                {t('timeline.sidebar.announcements.title')}
            </Text>
            {announcements?.map(annouce => {
                return (
                    <TitleAndContentComponent
                        key={annouce?.id}
                        title={annouce?.title}
                        author={annouce?.user?.name}
                        created_at={annouce?.created_at}
                        link={URL_ANNOUNCE_POST(annouce?.id)}
                    />
                )
            })}
        </div>
    )
}
export default SideAnnouncementsComponent
