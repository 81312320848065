import React, {useMemo} from 'react'
import {TComment} from '@api/timeline/type'
import Text from '@component/Text/Text'
import Link from 'next/link'
import {getLinkUrl, URL_TIMELINE_USER} from '@constant/url'
import {getURLs, urlValidation} from '@util/validation'
import TagText from '@component/Text/TagText'
import {DEFAULT_QUOTE_SYMBOL} from '@pages/chart/constants/chartConfig'
import {useRouter} from 'next/router'
import {parsingUrl} from '@util/strings'

interface IProps extends Pick<TComment, 'content' | 'mention_user_id' | 'mentioned_username'> {
    className?: string
}

const ReplyItemContents: React.FC<IProps> = ({className, content, mention_user_id, mentioned_username}) => {
    const router = useRouter()

    const contentText = useMemo(() => {
        let content_ = content
        // parse url
        if (urlValidation(content_)) {
            content_ = parsingUrl(content_)
        }

        // parse mentioned
        if (content?.search('@') !== -1) {
            if (!mentioned_username) return content_
            content_ = content_?.replace(
                `@${mentioned_username}`,
                `<span class="text-ti2 text-gray01 dark:text-dark_gray01 hover:underline" click_id="click_mentioned_name">@${mentioned_username}</span>`,
            )
        }

        return content_
    }, [content, mentioned_username])

    const contentsFunctionList = [
        {
            id: 'click_mentioned_name',
            func: () => router.push(getLinkUrl(URL_TIMELINE_USER(mention_user_id))),
        },
    ]

    return (
        <TagText
            className={`whitespace-pre-wrap text-body1 text-gray01 dark:text-dark_gray01 break-all ${className}`}
            funcList={contentsFunctionList}>
            {contentText || ''}
        </TagText>
    )
}
export default ReplyItemContents
