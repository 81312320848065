import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'

interface IProps {
    className?: string
    content?: string
}

const LINE_HEIGHT = 24
const TimelineItemContentItem = ({className, content}: IProps) => {
    const {t} = useTranslation()
    const [isLong, setIsLong] = useState(false)
    const [lineHeight, setLineHeight] = useState<number>()
    const textRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (textRef.current) {
            const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight)
            setLineHeight(lineHeight)
            const maxHeight = lineHeight * 4
            setIsLong(textRef.current.scrollHeight > maxHeight)
        }
    }, [])

    return (
        <div className={className}>
            <div ref={textRef} style={{maxHeight: (lineHeight || LINE_HEIGHT) * 4}} className={'overflow-hidden'}>
                <Text className={'break-all text-gray01 dark:text-dark_gray01'}>{content}</Text>
            </div>

            {isLong && (
                <Span className="text-gray01 dark:text-dark_gray01 underline">
                    {t('timeline.item.content.see.more.title')}
                </Span>
            )}
        </div>
    )
}
export default TimelineItemContentItem
