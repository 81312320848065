import React from 'react'

import TimelineEditorMainOperations from './TimelineEditorMainOperations'
import TimelineEditorOtherOperations from './TimelineEditorOtherOperations'

interface IProps {
    className?: string
    content?: string
    images?: string[]
    setImages: (images?: string[]) => void
    setHasImageError: (hasError: boolean) => void
    enablePostButton?: boolean
}

const TimelineEditorOperations: React.FC<IProps> = ({
    className,
    content,
    images,
    setImages,
    setHasImageError,
    enablePostButton,
}) => {
    return (
        <div
            className={`flex items-center justify-between border-t border-gray08 dark:border-dark_gray08 ${className}`}>
            <TimelineEditorMainOperations images={images} setImages={setImages} setHasImageError={setHasImageError} />
            <TimelineEditorOtherOperations content={content} enablePostButton={enablePostButton} />
        </div>
    )
}
export default TimelineEditorOperations
