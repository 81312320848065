import React, {useCallback} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

import {createLog} from '@util/logs'

import {URL_CHART_WITH_SYMBOL} from '@constant/url'
import {useNatsGetChartIndicator} from '@component/nats/hooks/useNatsGetChartIndicator'

import Text from '@component/Text/Text'
import WizTokenRankCard from '@component/cards/WizTokenRankCard'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
}

const SideHourlyTrendsComponent: React.FC<IProps> = ({className}) => {
    const router = useRouter()
    const {t} = useTranslation()

    const {data, isLoading} = useNatsGetChartIndicator(true)

    const routeToChart = useCallback((base_symbol: string) => {
        createLog('event', 'timeline_hourly_trends_coin_click', {symbol: base_symbol})
        void router.push(URL_CHART_WITH_SYMBOL(base_symbol))
    }, [])

    if (isLoading) {
        return (
            <div className={`flex flex-col ${className}`}>
                <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                    {t('timeline.sidebar.hourly.trends.title')}
                </Text>
                <div className={'w-full flex flex-col gap-y-[20px] my-[10px]'}>
                    {Array.from({length: 10}).map((_, index) => {
                        return (
                            <SkeletonBlock
                                key={index.toString()}
                                className={'w-full h-[21px] mx-auto'}
                                sizeClassName={'h-[21px]'}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className={`${className}`}>
            <Text className={'px-[20px] text-h4 text-gray01 dark:text-dark_gray01 mb-[5px]'}>
                {t('timeline.sidebar.hourly.trends.title')}
            </Text>
            {data?.token_trend?.map(token => {
                return (
                    <WizTokenRankCard
                        key={token.symbol}
                        token={token}
                        className={'px-[20px] py-[10px] hover:bg-gray09 dark:hover:bg-dark_gray09'}
                        rankClassName={'text-gray02 dark:text-dark_gray02'}
                        onClick={routeToChart}
                    />
                )
            })}
        </div>
    )
}
export default SideHourlyTrendsComponent
